<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pt-5">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logo.png')"
          max-height="100"
          max-width="200"
          alt="logo"
          contain
          eager
          class="app-logo"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pt-4">
      <div v-for="item in menuItems" :key="item.text">
        <nav-menu-link v-if="$tools.authorize(item.auth)"
          :to="item.route"
          :title="item.text"
          :icon="item.icon">
        </nav-menu-link>
      </div>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
import {
  mdiHomeOutline,
} from '@mdi/js'

export default {
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      menuItems: [
        {
          icon: this.icons.mdiHomeOutline, text: 'Result', route: 'result', auth: [],
        },
      ],
    }
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

</style>
